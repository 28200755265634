<template>
  <j-abm
    name="template"
    avatar="link"
    :suppliers="suppliers"
    :features="{
      search: false,
      create: true,
      update: true,
      delete: false,
    }"
    @create="onCreate"
    @update="onUpdate">
    <template #form="{item}">
      <div class="col-xl-5 col-lg-12">
        <h5>{{ $t('template') }}</h5>
      </div>
      <div class="col-xl-7 col-lg-12">
        <a-form-model
          ref="form"
          :model="item"
          :rules="rules">
          <j-input
            prop="name"
            v-model="item.name"
            icon="number"
            :format="f.title" />
          <j-store
            prop="store"
            v-model="item.store"
            :owner="account" />
          <j-enum-select
            prop="size"
            v-model="item.size" />
          <j-calendar
            prop="expiration"
            v-model="item.expiration" />
          <j-switch
            prop="payment"
            v-model="item.payment"
            :help="$t('msg.template.help')" />
        </a-form-model>
      </div>
    </template>
  </j-abm>
</template>

<script>
import lms from '@/api/lms'
import v from '@/utils/validators'
import f from '@/utils/formatters'
import JAbm from '@/views/shared/abm/update'
import JInput from '@/views/shared/forms/input'
import JStore from '@/views/shared/forms/store'
import JSwitch from '@/views/shared/forms/switch'
import JCalendar from '@/views/shared/forms/calendar'
import JEnumSelect from '@/views/shared/forms/enum-select'
export default {
  components: {
    JAbm,
    JInput,
    JStore,
    JSwitch,
    JCalendar,
    JEnumSelect,
  },
  data: function () {
    return {
      f,
      rules: {
        name: [v.required],
      },
    }
  },
  computed: {
    account() {
      return this.$route.params.account
    },
    suppliers() {
      return {
        fetchOne: id => lms.account.fetchTemplate(this.account, id),
        payload: () => {
          return {
            method: 'EXPRESS',
            account: this.account,
          }
        },
      }
    },
  },
  methods: {
    onCreate(template) {
      this.onSubmit(template, template => lms.account.createTemplate(this.account, template))
    },
    onUpdate(template) {
      this.onSubmit(template, template => lms.account.updateTemplate(this.account, template))
    },
    onSubmit(template, callback) {
      Promise.all([
        new Promise((resolve, reject) => this.$refs.form.validate(resolve)),
      ]).then(results => {
        if (results.every(Boolean)) {
          callback(template).then(_ => this.$router.push(`/accounts/${this.account}/templates`))
        }
      })
    },
  },
}
</script>
