<template>
  <a-form-model-item :prop="prop" :colon="false" has-feedback>
    <span slot="help" class="text-dark"></span>
    <span slot="label" class="text-dark">{{$t(prop)}}</span>
    <div class="text-secondary">
      {{ help }}
    </div>
    <a-switch v-model="bool" />
  </a-form-model-item>
</template>

<script>
export default {
  name: 'JSwitch',
  props: [
    'value',
    'prop',
    'help',
  ],
  computed: {
    bool: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
        this.$emit('change', value)
      },
    },
  },
}
</script>
