<template>
  <a-form-model-item :prop="prop" :colon="false" has-feedback>
    <span slot="help" class="text-dark"></span>
    <span slot="label" class="text-dark">{{$t(prop)}}</span>
    <a-date-picker
      v-model="date"
      :disabled-date="disabled"
      size="large" />
  </a-form-model-item>
</template>

<script>
import moment from 'moment'
export default {
  name: 'JCalendar',
  props: [
    'value',
    'prop',
  ],
  computed: {
    date: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
        this.$emit('change', value)
      },
    },
  },
  methods: {
    disabled(current) {
      return current && current < moment().endOf('day')
    },
  },
}
</script>
