var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('j-abm',{attrs:{"name":"template","avatar":"link","suppliers":_vm.suppliers,"features":{
    search: false,
    create: true,
    update: true,
    delete: false,
  }},on:{"create":_vm.onCreate,"update":_vm.onUpdate},scopedSlots:_vm._u([{key:"form",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"col-xl-5 col-lg-12"},[_c('h5',[_vm._v(_vm._s(_vm.$t('template')))])]),_c('div',{staticClass:"col-xl-7 col-lg-12"},[_c('a-form-model',{ref:"form",attrs:{"model":item,"rules":_vm.rules}},[_c('j-input',{attrs:{"prop":"name","icon":"number","format":_vm.f.title},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('j-store',{attrs:{"prop":"store","owner":_vm.account},model:{value:(item.store),callback:function ($$v) {_vm.$set(item, "store", $$v)},expression:"item.store"}}),_c('j-enum-select',{attrs:{"prop":"size"},model:{value:(item.size),callback:function ($$v) {_vm.$set(item, "size", $$v)},expression:"item.size"}}),_c('j-calendar',{attrs:{"prop":"expiration"},model:{value:(item.expiration),callback:function ($$v) {_vm.$set(item, "expiration", $$v)},expression:"item.expiration"}}),_c('j-switch',{attrs:{"prop":"payment","help":_vm.$t('msg.template.help')},model:{value:(item.payment),callback:function ($$v) {_vm.$set(item, "payment", $$v)},expression:"item.payment"}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }